import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83')
];

export const server_loads = [12,4];

export const dictionary = {
		"/(landing)": [55],
		"/(rgpd)/(uppercase)/CGU": [59,[11,12]],
		"/(rgpd)/(uppercase)/CGV": [60,[11,12]],
		"/(user-creation)/account-validation/[token]": [63,[13]],
		"/(d)/admin": [31,[4,7]],
		"/(d)/admin/interventions": [32,[4,7]],
		"/(d)/admin/pricing": [33,[4,7]],
		"/(d)/admin/stats": [34,[4,7]],
		"/(d)/admin/teams": [35,[4,7]],
		"/(d)/admin/users": [36,[4,7]],
		"/(d)/assistant": [37,[4]],
		"/(d)/assistant/create": [39,[4]],
		"/(d)/assistant/[refId]": [38,[4]],
		"/a/[refId]": [67,[14]],
		"/a/[refId]/accept": [68,[14]],
		"/a/[refId]/annotation": [69,[14]],
		"/a/[refId]/assistant": [70,[14]],
		"/a/[refId]/infos": [71,[14]],
		"/a/[refId]/report": [72,[14]],
		"/a/[refId]/settings": [73,[14]],
		"/a/[refId]/widget": [74,[14]],
		"/(rgpd)/cgu": [61,[11]],
		"/(rgpd)/cgv": [62,[11]],
		"/(d)/contacts": [40,[4]],
		"/(d)/(settings)/credits": [~21,[4,5]],
		"/(user-creation)/email-sent": [64,[13]],
		"/(user-creation)/engagement/[key]": [65,[13]],
		"/(landing)/en": [56],
		"/exposition": [75],
		"/(fixee-tests)/fixee-tests-2": [54,[10]],
		"/(fixee-tests)/fixee-tests": [53,[9]],
		"/(landing)/fr": [57],
		"/(d)/home": [41,[4]],
		"/(d)/interventions": [42,[4]],
		"/(d)/interventions/[refId]": [43,[8]],
		"/(d)/interventions/[refId]/accept": [44,[8]],
		"/(d)/interventions/[refId]/annotation": [45,[8]],
		"/(d)/interventions/[refId]/assistant": [46,[8]],
		"/(d)/interventions/[refId]/infos": [47,[8]],
		"/(d)/interventions/[refId]/report": [48,[8]],
		"/(d)/interventions/[refId]/settings": [49,[8]],
		"/(d)/interventions/[refId]/widget": [50,[8]],
		"/(d)/(settings)/invoices": [22,[4,5]],
		"/i/[refId]": [76,[15]],
		"/i/[refId]/accept": [77,[15]],
		"/i/[refId]/annotation": [78,[15]],
		"/i/[refId]/assistant": [79,[15]],
		"/i/[refId]/infos": [80,[15]],
		"/i/[refId]/report": [81,[15]],
		"/i/[refId]/settings": [82,[15]],
		"/i/[refId]/widget": [83,[15]],
		"/(auth)/login": [16,[2]],
		"/(user-creation)/portal-init": [66,[13]],
		"/(landing)/pricing_old": [58],
		"/(d)/(settings)/pricing": [23,[4,5]],
		"/(d)/(settings)/pricing/credits": [24,[4,5]],
		"/(d)/(settings)/procedures": [25,[4,5]],
		"/(d)/(settings)/procedures/(subgroup)/create": [27,[4,5,6]],
		"/(d)/(settings)/procedures/(subgroup)/[procedureId]": [26,[4,5,6]],
		"/(d)/(settings)/profile": [28,[4,5]],
		"/(auth)/register": [17,[2]],
		"/(auth)/request-reset-password": [18,[2]],
		"/(auth)/request-reset-password/email-sent": [19,[3]],
		"/(auth)/reset-password/[token]": [20,[2]],
		"/(d)/statistics": [51,[4]],
		"/(d)/team-reports/[shortId]": [52,[4]],
		"/(d)/(settings)/teams/[id]": [29,[4,5]],
		"/(d)/(settings)/whatsapp": [30,[4,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';